import React from "react"

import Layout from "../../components/Layout"
import { Link } from "gatsby"


export default function Home() {
  return (
    <Layout breadcrumbs={["Equipe"]}>
      <div id="heading1" >
        <h1>Tiago C. Lazier</h1>
      </div>

      {/* Main */}
      <section id="main" className="wrapper">
        <div className="inner">

          <div className="content page">

            {<h5><Link to="/">Inicio</Link> &gt;&gt; Equipe  &gt;&gt; Tiago C. Lazier</h5>}

            <header>
              <h2>Cofundador</h2>
            </header>

            <div style={{ display: 'flex' }}>
              <div>
                <p>Eu pesquiso as dinâmicas materiais-linguísticas da ação e dos significados 
                que guiam o comportamento das pessoas. Gosto de atuar em projetos na interseção 
                da filosofia, política, estética e tecnologia, incluindo o ensino e a 
                investigação acadêmica. Sou doutor em Ciência Política pela USP, mestre em 
                Economia Pública, Direito e Política pela Leuphana Universität, Alemanha, e 
                bacharel em Relações Internacionais.
                </p>
                <p>Como cofundador do Engajados, exerço atualmente a coordenação de 
                projetos e pesquisa, com ênfase na experiência do usuário. Junto a 
                uma equipe multidisciplinar, desenvolvo plataformas que tentam 
                ressignificar o papel da tecnologia, como facilitadora da inteligência 
                e da sensibilidade humana colaborativa.
                </p>
                {/* <ul>
                  <li>Acesso ao projeto (versão beta) em: <a href="http://monitoraea.org.br/" target="_blank">www.monitoraea.org.br</a></li>
                </ul> */}
              </div>
              {/* <div>
                <span className="image special"><img src={lab1} alt="" /></span>
                <span className="image special"><img src={lab2} alt="" /></span>
                <span className="image special"><img src={lab1} alt="" /></span>
              </div> */}
            </div>

          </div>
        </div>
      </section>
    </Layout>
  )
}
